* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-primary;
  font-weight: 300;
  font-size: 11px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  background-color: $white;
  max-width: 100%;
  overflow-x: hidden;

  &.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
}

.brap{
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  padding-top: $header-height;
  min-height: 100vh;
  transition: transform 0.3s;

  .page {
    flex: 1;
  }

  @include mobile {
    padding-top: 0;
  }
}

.img-in-picture {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.no-margin {
  margin: 0;
}

@media all and (min-width: 769px) {
  .mobile {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .desk {
    display: none;
  }
}

.container {
  padding: 0 16px;
  max-width: $container-width;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  + .container {
    margin-top: 96px;
  }

  .row {
    display: flex;
    justify-content: center;

    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .col {
    flex: 1;
    padding: 0 16px; //So 32px margin between the items and on the outside (because the container gives 16px too)
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--cols {
    flex-direction: row;
    padding: 0;

    .row {
      display: flex;
      flex-direction: row;
    }

    .col {
      justify-content: flex-start;
    }
  }

  &--rows {
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      margin-bottom: 32px;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    .row {
      margin-bottom: 32px;

      &:last-child {
        margin: 0;
      }

      flex-direction: column;
      width: 100%;
    }

    .col {
      margin-bottom: 54px;
      display: block;

      &:last-child {
        margin: 0;
      }
    }

    .button {
      max-width: 100%;
      max-width: 100%;
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.anchor-top{
  content: '';
  display: block;
  transform: rotate(180deg);
  margin: -48px auto 0;
  height: 85px;
  width: 85px;
  background: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="85.04" height="85.04"><path fill="%23000" d="M43.335 45.312l-5.561-5.561a.645.645 0 1 1 .912-.912l4.649 4.649L47.823 39a.645.645 0 1 1 .912.912l-5.4 5.4z"/></svg>');
}