.form{
    max-width: 768px;

    label{
        color: $font-color;
        font-size: 14px;
        font-weight: 300;

        width: 100%;
    }

    input,
    textarea{
        font-family: $font-primary;
        font-weight: 400;
        font-size: 18px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: geometricPrecision;

        color: $primary;
        background: none;
        
        margin: 0 0 54px;
        min-height: 42px;
        width: 100%;
        border: none;
        border-bottom: 1px solid $primary;

        &::placeholder{
            color: #BBBBBB;
        }
    }

    textarea{
        min-height: 108px;
        padding: 8px 0;
    }

    .form-errors {
        background: $primary;
        color: #fff;
        padding: 16px;
        border-radius: 5px;
        margin: 0 0 22px;
    }

    .has-error{
        input,
        textarea{
            border-color: $primary;
            border:1px solid $primary;
            border-radius: 5px 5px 0 0;
            margin-bottom: 0;
            float: left;
            text-indent: 8px;
        }
    }

    ul.errors {
        display: flex;
        align-items: center;

        margin: 0 0 54px;
        padding: 0 10px;
        height: 24px;
        
        list-style-type: none;
        background: $primary;
        border-radius: 0 0 5px 5px;
        

        li{
            color: #fff;
            font-size: 13px;
        }
    }
}

.mailchimp-form{

    .input-field{
        display: flex;
        margin: 0 0 4px;
    }

    input{
        border: 1px solid rgba(255,255,255,0.5);
        color: #fff;
        padding: 0 8px;
        font-size: 9pt;
        width: 100%;
        min-height: 32px;
        margin: 0;
        border-right: none;
    
        &::placeholder{
            color: #fff;
        }
    }

    input[type="submit"]{
        border: 2px solid #fff;
        text-transform: uppercase;
        width: auto;
    }
}