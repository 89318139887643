.component--header {
  position: relative;
  display: flex;

  padding: 0;

  background: $primary;

  .anchor-chevron{
      position: absolute;
      left: 50%;
      transform: translateX(-50%) scale(1.5);
      bottom: -20px; 
      height: 85px;
      width: 85px;
      //background: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="85.04" height="85.04"><path fill="%23fff" d="M43.335 45.312l-5.561-5.561a.645.645 0 1 1 .912-.912l4.649 4.649L47.823 39a.645.645 0 1 1 .912.912l-5.4 5.4z"/></svg>');
  }

  @include mobile {
    flex-direction: column;
  }

  .header-image {
    position: relative;
    height: auto;
    width: 50%;
    padding: 56% 0 0;
    padding: 37.5% 0 0;

    @include mobile {
      padding: 75% 0 0;
      width: 100%;
    }

    .img-in-picture {
      position: absolute;
      top: 0;
    }
  }

  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 50%;

    padding: 32px 0;

    @include mobile {
      width: 100%;
      justify-content: center;
    }

    &.center-content {
      .paragraph {
        margin: auto;
      }
    }

    .paragraph {
      // margin: auto auto 14%;
      max-width: 343px;
      color: #fff;
      font-size: 12pt;
      line-height: 23pt;
      white-space: pre-line;
    }

    .image {
      display: flex;
      max-height: 25%;

      @include desktop {
        max-height: 80px;
        max-width: 50%;
        margin: 0 0 8px;
      }

      @include mobile {
        max-width: 33%;
        margin: 24px auto 0;
      }

      .img-in-picture{
        object-fit: contain;
      }
    }
  }

  @media (min-width: 768px) {
  }
}

.scrollHint {
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);

  span {
    display: block;
    margin-bottom: 6px;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: #fff;
    opacity: 0.5;

    animation-name: scrollHint;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    transition: all 0.2s;

    &:nth-child(2) {
      animation-delay: 0.15s;
    }

    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  }
}
