#styleguide{
  display: grid;
  grid-template-columns: 20vw 80vw;

  min-height: 100vh;

  .row{
    padding: 0 16px;
  }
}

.styleguide__header{
  height: 200px;
  width: 100%;
  background: $primary-gradient;

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  .container{
    height: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0 20vw;
  }

  .section-title{
    color: #fff;
  }
}

.styleguide__menu{
  z-index: 3;

  padding: 16px 0 0;
  width: 20vw;
  min-height: 100vh;
  background: #eee;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;

  div{
    display: flex;
    align-items: center;

    padding: 0 20px;
    height: 42px;

    color: $font-color;
    cursor: pointer;

    &.active{
      background: #ccc;
    }
  }
}

.styleguide__content{
  padding: 54px 0 96px 32px;

  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.styleguide-colors{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  .color{
    display: flex;
    flex-direction: column;

    position: relative;
    overflow: hidden;

    margin: 0 10px 10px 0;
    padding: 10px;

    border-radius: 5px;
    
    width: 100%;
    max-width: 199px;
    min-height: 200px;

    // &:first-child{
    //   margin-left: 0;
    // }

    // &:last-child{
    //   margin-right: 0;
    // }

    .hexcode{
      margin: auto 0 0;
    }

    p{
      font-size: 14px;
    }

    p:first-child{
      font-weight: bold;
    }

    .hexcode,
    .scssCode,
    p:nth-child(2){
      opacity: 0.7;
    }

    &.bkgr-white{
      box-shadow: 0 0 0 1px #f2f2f2;
    }
  }

  .multi-color{
    padding: 0;

    > * {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;

      padding: 0 0 0 10px;
      height: 100%;

      p.hexcode{
        margin: 0;
      }
    }
  }
}

.typography-info{
  opacity: 0.7;
  font-size: 14px;
}

.styleguide-title{
  position: relative;
  display: inline-block;
  width: fit-content;

  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: -1007px;
    height: 17px;
    width: 1000px;
    background: #eee;
    transform: skewX(-10deg);
  }

  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    right: -2007px;
    height: 17px;
    width: 2000px;
    background: #eee;
    transform: skewX(-10deg);
  }
}

.styleguide-buttons{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column: 16px;

  @media all and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media all and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .paragraph{
    padding: 16px;

    &:nth-child(even){
      background: #f2f2f2;
    }
  }

  .button{
    margin: 0 0 16px;
  }
}

.code-tag {
  background: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 80%;
  font-weight: normal;
  padding: 2px 3px 1px;
}
