  .iconsection{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-bottom: 80px;

    .section-title{
      margin: 0 0 6px;
    }
  
    .component--icon{
      margin: 0 0 24px;
      padding: 24px;
      height: 150px;
      width: 150px;
      border-radius: 100%;
    }
  
    .paragraph.title{
      margin-bottom: 14px;
    }
  }
