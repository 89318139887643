
.quote {
  padding: 20px;
  width: fit-content;
  max-width: 750px;
  border-radius: 5px;
  background: #f2f2f2;

  .paragraph:first-child {
    margin: 0;

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }

    &:before,
    &:after {
      font-size: 18px;
      color: #1860af;
    }
  }

  .paragraph:last-child {
    text-align: right;
    color: #1860af;
    margin: 0;
  }
}
