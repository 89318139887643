.component--related {
}

.related-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.related-item {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 12px 8px 0;
  padding: 4px 12px;

  border: 1px solid #666;

  text-transform: uppercase;
  text-decoration: none;
  color: #000;

  &:last-child {
    margin: 0 0 8px;
  }

  &.theme-active {
    border: none;
  }
}
