$primary: #CD998D;

$font-color: #000;
$black: #000;
$darkgrey: #4a4a4a;
$grey: #9b9b9b;
$lightgrey: #f2f2f2;
$white: #fff;

$primary-gradient: linear-gradient(45deg, #5288c3 0%, #3fccde 100%);
