@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600");

//Font styles
.section-title {
  font-size: 14pt;
  line-height: 115%;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;

  margin-bottom: 22px; //32px - 10px from the line-height

  // @media all and (max-width: 768px) {
  //   font-size: 36px;
  //   line-height: 32px;
  // }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.section-subtitle {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: -12px 0 40px;
}

// .item-title {
//     font-size: 24px;
//     line-height: 26px;
//     font-style: italic;
//     font-weight: bold;
//     letter-spacing: 0px;
//     text-transform: uppercase;
//     color: $primary;

//     padding: 0;
//     margin-bottom: 16px;

//     @media all and (max-width: 768px) {
//         font-size: 20px;
//         line-height: 24px;
//     }
// }

// .item-subtitle {
//     font-size: 20px;
//     line-height: 22px;
//     font-style: normal;
//     font-weight: 300;
//     letter-spacing: 0px;
//     text-transform: uppercase;

//     padding: 0;

//     @media all and (max-width: 768px) {
//         font-size: 20px;
//         line-height: 22px;
//     }
// }

// .title {
//     font-size: 24px;
//     line-height: 26px;
//     font-style: italic;
//     font-weight: bold;
//     letter-spacing: 0px;
//     text-transform: uppercase;
// }

.paragraph {
  font-size: 10pt;
  line-height: 200%;
  font-weight: 300;
  color: $font-color;

  width: 100%;
  max-width: 600px;
  margin-bottom: 32px;

  &.pre-line {
    white-space: pre-line;
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .accent {
    font-weight: normal;
    color: inherit;
  }

  a {
    color: $font-color;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
      color: #CD998D;
    }
  }

  // @media all and (max-width: 768px) {
  //     font-size: 16px;
  // }
}

div.paragraph p {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.lighter {
  font-weight: 300;
  font-style: normal;
}

ul li {
  font-size: 18px;
  line-height: 130%;
  font-weight: 300;
  color: $font-color;
}
