.component {
  padding: 96px 0 0;
  text-align: center;

  @media all and (max-width: 768px) {
    padding: 54px 0;
  }

  img {
    max-width: 100%;
    flex-shrink: 0;
  }

  .paragraph,
  p {
    //P -> Ugly fix for WYSIWYG
    max-width: 496px;
    margin: 0 auto;

    font-size: 10pt;
    line-height: 200%;
    width: 100%;
    max-width: 600px;

    margin-bottom: 32px;
    font-weight: 300;

    .accent {
      font-weight: normal;
      color: inherit;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include mobile {
      font-size: 13px;
    }
  }

  .button {
    height: 60px;

    @media all and (max-width: 768px) {
      max-width: 100%;
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.no-padding {
    padding: 0;
  }

  &--agenda,
  &--workshops{
    padding: 16px 0 0;
  }
}
