.main-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 400;

  padding: 0 0 30px;
  height: $header-height;
  width: 100%;

  background-color: $white;
  color: $black;
  box-shadow: -1px -4px 14px rgba(0, 0, 0, 0.4);

  transition: all 0.3s;

  @include mobile {
    align-items: center;

    padding: 0;
    height: 60px;
    background: none;

    box-shadow: none;
  }

  a {
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s;
    position: relative;
    z-index: 1;

    @include mobile{
      color: #fff;
    }
  }

  .logo__image {
    height: 50px;
    transition: all 0.5s;

    @include mobile {
      height: 44px;
      display: none;
    }
  }

  @include mobile {
    .logo {
      margin: 0 0 0 16px;
    }
  }

  &.minimal {
    height: 70px;
    padding: 0 0 12px;

    .logo__image {
      height: 42px;

      @include mobile {
        height: 36px;
      }
    }
  }
}

.main-menu__left,
.main-menu__right {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding: 0 16px;
  width: 100%;
  list-style-type: none;

  @include mobile {
    display: none;
  }
}

.nav-item {
  position: relative;
  cursor: pointer;
  line-height: 85%;

  @include desktop{

    &:hover {
      .main-menu__link {
        color: $primary;
      }

      .nav-item__children {
        display: block;

        .main-menu__link {
          color: $black;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

.main-menu__link {
  font-weight: 400;
  font-size: 7pt;

  &:hover {
    font-weight: 600;
  }

  @include mobile {
    font-size: 24px;
    height: 48px;
    display: flex;
    align-items: center;
  }
}

.nav-item__children {
  list-style-type: none;

  @include mobile {
    padding: 0 0 16px;
  }

  .nav-item {
    @include desktop {
      padding: 2px 8px;
      background: #fff;
    }

    @include mobile {
      padding: 0 0 0 16px;
    }
  }

  .main-menu__link {
    font-size: 8pt;
    font-weight: 300;
    text-transform: none;

    @include mobile {
      font-size: 12px;
      height: 32px;
      display: flex;
      align-items: center;
    }
  }
}

.nav-item__children {
  @include desktop {
    display: none;
    position: absolute;
    top: 0;
    left: -8px;

    padding: 32px 0 0;
    min-width: 200px;
  }
}

.social-menu {
  display: flex;
  flex-direction: row;

  list-style-type: none;

  // margin: 0 0 0 auto;

  li {
    margin: 0 0 0 16px;
  }

  @include mobile {
    justify-content: space-around;
    position: absolute;
    left: 0;
    bottom: 32px;
    width: 100%;

    li {
      margin: 0;

      .icon-fb:before,
      .icon-insta:before,
      .icon-fm:before {
        width: 24px;
        max-height: 26px;
      }

      .icon-insta:before {
        width: 20px;
      }

      .icon-fb:before {
        width: 11px;
      }
    }
  }
}

.icon-fb:before,
.icon-insta:before,
.icon-fm:before {
  content: 'hello';
  display: block;
  width: 16px;
  max-height: 18px;
}

.icon-fb:before {
  content: url('data:image/svg+xml, <svg aria-hidden="true" data-prefix="fab" data-icon="facebook-f" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-facebook-f fa-w-10 fa-2x"><path fill="%23000" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>');
  width: 8px;
  margin: -2px 0 0;
}

.icon-insta:before {
  content: url('data:image/svg+xml, <svg aria-hidden="true" data-prefix="fab" data-icon="instagram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-instagram fa-w-14 fa-3x"><path fill="%23000" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>');
}

.icon-fm:before {
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116 70"><g fill="%23000" fill-rule="nonzero"><path d="M40.39 13.853l9.64-9.506s8.783-8.772 18.825 1.487l46.525 46.742-8.669 8.669s-9.392 9.681-19.072 0S40.39 13.853 40.39 13.853zM36.344 53.913c4.274 4.294 8.495 8.53 11.631 11.667 9.681 9.681 19.072 0 19.072 0l8.669-8.669-28.97-29.187c-10.042-10.259-18.825-1.487-18.825-1.487l-9.64 9.506-.015.048L.602 53.713l8.486 8.621s9.392 9.681 19.072 0l8.184-8.421z"/></g></svg>');
  margin: -2px 0 0;
}

.icon-hamburger:before {
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-bars fa-w-14 fa-3x" data-icon="bars" data-prefix="far" viewBox="0 0 448 512"><path fill="%23fff" d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"/></svg>');
}

.icon-close:before {
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-times fa-w-10 fa-3x" data-icon="times" data-prefix="far" viewBox="0 0 320 512"><path fill="%23fff" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg>');
}

/*Mobile menu*/
#mobile_menu_button {
  margin: 0 16px 12px 0;
  height: 26px;
  width: 26px;
  background: none;
}

.menu-mobile {
  position: fixed;
  z-index: 10;
  top: 0;
  right: -100vw;

  display: flex;
  flex-direction: column;

  padding: 60px 32px 0;

  width: 100vw;
  height: 100vh;
  background: #CD998D;

  box-shadow: 0 0 50px rgba(0, 0, 0, 0);
  transition: transform 0.3s;

  ul {
    list-style-type: none;
  }

  // ul + ul {
  //   margin-top: 32px;
  // }

  .icon-close {
    position: absolute;
    z-index: 3;
    top: 16px;
    right: 20px;

    height: 20px;
    width: 20px;

    background: none;
  }

  .social-menu{
    display: none;
  }

  .nav-item__children{
    display: none;
  }

  .nav-item.open{
    .nav-item__children{
      display: block;
    }
  }

  .nav-item.open{
    .nav_open {
      &:before{
        transform: rotate(0deg);
      }
    }
  }


  .nav_open {
    &:before{
      display: block;
      content: url('data:image/svg+xml, <svg aria-hidden="true" data-prefix="fas" data-icon="caret-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10 fa-3x"><path fill="%23fff" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
      width: 20px;
      transform: rotate(-90deg);
      transition: all 0.2s;
    }
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;
    height: 48px;
    width: 48px;
    z-index: 100;
  }
}

.mobile-menu-active {
  // #app {
  //   transform: translateX(-100vw);
  // }

  .menu-mobile {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
    transform: translateX(-100vw);
  }
}
