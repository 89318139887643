.content-page{

}

.content-page__header{
  position: relative;
  padding: 166px 0 32px;

  @include blobLeftWhite;

  @include blobRightWhite;

  .section-title{
    color: $white;
  }
}

.content-page__content{
  position: relative;
  overflow: hidden;

  padding: 54px 0;
  min-height: 600px;

  transition: all 0.3s;

  @include blobLeftBlue;

  @include blobRightBlue;
}