button {
    outline: none !important;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border: none;

    &:disabled{
        box-shadow: none !important;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

@mixin button--primary {
    background-color: $primary;
    color: $white;
    box-shadow: 0 0px 0 rgba(24, 96, 175, 0.3);

    &:hover {
        box-shadow: 0 0 0 5px rgba(24, 96, 175, 0.3),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }

    &:active {
        box-shadow: 0 0 0 50px rgba(24, 96, 175, 0),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }
}

@mixin button--white {
    background-color: $white;
    color: #1860af;

    box-shadow: 0 0px 0 rgba(255, 255, 255, 0.4);

    &:hover {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }

    &:active {
        box-shadow: 0 0 0 50px rgba(255, 255, 255, 0),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }
}

@mixin button--outline{
    border: 2px solid $primary;
    color: $primary;
    background-color: transparent;

    box-shadow: 0 0px 0 rgba(24, 96, 175, 0.3);

    &:hover {
        box-shadow: 0 0 0 5px rgba(24, 96, 175, 0.3),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }

    &:active {
        box-shadow: 0 0 0 50px rgba(24, 96, 175, 0),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }

    &.button--white {
        @include button--outline--white();
    }
}

@mixin button--outline--white{
    border-color: $white;
    color: $white;
    background-color: transparent;

    box-shadow: 0 0px 0 rgba(255, 255, 255, 0.4);

    &:hover {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }

    &:active {
        box-shadow: 0 0 0 50px rgba(255, 255, 255, 0),
            0 1px 5px rgba(0, 0, 0, 0.5);
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;

    width: 100%;
    max-width: 250px;
    height: 42px;
    border-radius: $button-radius;
    border: none;

    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;

    transition: all 0.2s;
    outline: none;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;

    &--primary {
        @include button--primary();
    }

    &--white {
        @include button--white();
    }

    &--outline {
        @include button--outline();
    }

    @keyframes loadingdots {
        0% {
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .loading-dots{
        display: flex;

        span{
            animation: 0.6s infinite loadingdots;

            &:nth-child(2n){
                animation-delay: 0.2s;
            }

            &:nth-child(3n){
                animation-delay: 0.4s;
            }
        }
    }

    // &.loading{
    //     .loading-dots{
    //         display: flex;
    //     }
    // }
}
