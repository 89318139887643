.instagram-feed {
  display: flex;
  flex-direction: row;
  padding: 0 0 54px;
}

.instagram-feed__item {
  display: flex;
  margin: 0 16px 0 0;
  border: 1px solid #666;

  &:last-child {
    margin: 0;

    @include mobile {
      display: none;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.instagram-feed-popup {
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
}

.instagram-feed-popup__content {
  position: relative;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.instagram-feed-popup__footer {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 42px;

  .paragraph {
    margin: 0;
  }
}
