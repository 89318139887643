.component--content {
  &.has-sidebar {
    .container {
      display: flex;
      flex-direction: row;

      @include mobile {
        flex-direction: column;
      }
    }

    .col {
      justify-content: flex-start;
    }

    .col:last-child {
      max-width: 25%;

      @include mobile {
        max-width: 100%;
      }
    }

    text-align: left;

    .paragraph {
      margin-left: 0;
      white-space: pre-line;
    }
  }
}

.accent-title{
  font-size: 12pt;
  font-weight: 300;
  white-space: pre-line;
  margin-bottom: 80px;
}

.component--text {
  margin-bottom: 64px;

  &:last-child {
    margin-bottom: 0;
  }
}

.component--html {
  margin-bottom: 64px;

  @media all and (min-width: 960px) {
    margin-left: calc(-50vw + 464px);
    width: calc(100vw - 32px);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
