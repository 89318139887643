@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
            eot: "?",
            svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
            otf: "opentype",
            ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font: {
            family: quote($name);
            style: $style;
            weight: $weight;
        }
        src: $src;
    }
}

@mixin desktop {
    @media all and (min-width: 769px) {
        @content;
    }
}

@mixin mobile {
    @media all and (max-width: 768px) {
        @content;
    }
}

@mixin blobLeftWhite{
    &:before{
        content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='338px' height='228px' viewBox='0 0 338 228'><path fill='white' fill-rule='nonzero' d='M276.926-130.53c34.7 20.402 56.4 68.008 60.4 119.614 4 51.606-9.7 107.312-44.4 154.618-34.6 47.305-90.301 86.41-143.802 84.21-53.5-2.1-104.901-45.406-151.402-92.711-46.5-47.306-88.101-98.712-93.201-155.218-5-56.407 26.5-117.914 73-138.316 46.501-20.303 108.002.5 162.503 7.5 54.6 7.001 102.201 0 136.902 20.303z'/></svg>");
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 228px;
        width: 338px;
        opacity: 0.05;
    }
}

@mixin blobRightWhite{
    &:after{
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325 395"><path fill="white" fill-rule="nonzero" d="M372.926 36.47c34.7 20.402 56.4 68.008 60.4 119.614 4 51.606-9.7 107.312-44.4 154.618-34.6 47.305-90.301 86.41-143.802 84.21-53.5-2.1-104.901-45.406-151.402-92.711-46.5-47.306-88.101-98.712-93.201-155.218-5-56.407 26.5-117.914 73-138.316 46.501-20.303 108.002.5 162.503 7.5 54.6 7.001 102.201 0 136.902 20.303z"/></svg>');
        display: block;
        position: absolute;
        bottom: 0;
        transform: translateY(20%);
        right: 0;
        height: 395px;
        width: 325px;
        opacity: 0.05;
    }
}

@mixin blobLeftBlue{
    &:before{
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299 395"><path fill="%231860AF" fill-rule="nonzero" d="M237.926 36.47c34.7 20.402 56.4 68.008 60.4 119.614 4 51.606-9.7 107.312-44.4 154.618-34.6 47.305-90.301 86.41-143.802 84.21-53.5-2.1-104.901-45.406-151.402-92.711-46.5-47.306-88.101-98.712-93.201-155.218-5-56.407 26.5-117.914 73-138.316 46.501-20.303 108.002.5 162.503 7.5 54.6 7.001 102.201 0 136.902 20.303z"/></svg>');
        display: block;
        position: absolute;
        z-index: -1;
        top: 20%;
        left: 0;
        height: 395px;
        width: 299px;
        opacity: 0.03;
        transform: scaleY(-1);
    }
}

@mixin blobRightBlue{
    &:after{
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299 404"><path fill="%231860AF" fill-rule="nonzero" d="M110.998 91.955c-30.686 20.614-67.97 44.73-90.76 91.462-22.69 46.832-30.886 116.479-.2 160.308 30.587 43.83 100.156 62.042 167.726 60.14 67.67-1.9 133.34-23.815 156.53-67.645 23.19-43.93 3.699-109.674 4.399-176.219.7-66.545 21.59-133.79-1.6-154.404-23.09-20.714-90.26 5.204-135.84 26.718-45.58 21.615-69.668 38.926-100.255 59.64z"/></svg>');
        display: block;
        position: absolute;
        z-index: -1;
        bottom: 20%;
        right: 0;
        height: 404px;
        width: 299px;
        opacity: 0.03;
    }
}