.lightbox{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: none;
    align-items: center;
    justify-content: center;

    padding: 16px;

    opacity: 0;
    background: rgba(0,0,0,0.9);

    transition: all 0.3s;

    &.active{
        display: flex;
        opacity: 1;
    }

    .jwplayer{
        max-width: 768px;
    }
}

#lightbox__close{
    display: block;
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    background: none;
    color: #fff;
}