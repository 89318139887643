.page--prices{
  text-align: center;

  position: relative;
  padding: 166px 0 32px;
  background: $primary-gradient;

  @include blobLeftWhite;

  @include blobRightWhite;

  @media all and (max-width: 768px) {
    padding: 128px 0 32px;
  }

  .section-title,
  .section-subtitle,
  .item-subtitle,
  .paragraph{
    color: $white;
  }

  .container{
    align-items: center;

    .row{
      width: 100%;
    }
  }
}
