.footer {
  position: relative;
  padding: 54px 0 16px;
  background: $primary;

  @include mobile {
    padding-bottom: 0;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @include desktop {
      & + .container {
        margin-top: 54px;
      }
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .col {
    max-width: 33.33%;
    padding: 0 5%;

    @include mobile {
      max-width: 100%;
      padding: 0 12px;
    }
  }

  .item-title,
  .paragraph,
  a,
  a:hover {
    color: $white;
    width: 100%;
    line-height: 16pt;
    font-size: 9pt;
  }

  a {
    font-weight: 300;
  }

  .item-title {
    text-transform: uppercase;
    margin-bottom: 24px;
    font-size: 12pt;
  }

  // .col{
  //     flex-wrap: wrap;
  //     flex-direction: row;

  //     .footer-menu__link{
  //         width: 100%;
  //     }
  // }

  .footer__credits {
    .paragraph {
      max-width: 100%;
      text-align: center;
      margin: 0;
      font-size: 7pt;
    }

    @include mobile {
      margin: 0;
      padding: 5px 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
